import React, { useEffect, useState } from 'react';
import Confetti from "react-confetti";

const PaymentReceived = () => {
    const [runConfetti, setRunConfetti] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setRunConfetti(false);
        }, 3000);
    }, []);
    
    return (
        <>
            <Confetti
            recycle={runConfetti}
            numberOfPieces={200}
            />
            <div className='tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-h-full tw-min-h-[40vh]'>
                <div className='tw-max-w-[600px] tw-text-center'>
                <h3 className="tw-text-4xl tw-pb-8 tw-font-bold">Congrats! You're going on a CloudSafari adventure!</h3>
                <div>
                    <h5 className="tw-text-normal">Please check your inbox for a confirmation email. If you have any questions, please reach out to our team at <a className="tw-text-safari-green" href="mailto:hello@mycloudsafari.com">hello@mycloudsafari.com</a>.</h5>
                </div>
                </div>
            </div>
        </>
    )
};

export default PaymentReceived;